<style scoped>
.bmap {
  width: 100%;
  height: 100%;
}
.handler {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<template>
  <div :style="control.style">
    <baidu-map
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      :mapStyle="mapStyle"
      class="bmap"
      @ready="onReady"
    >
      <bm-marker
        v-for="point in points"
        :key="point.key"
        :position="point.lnglat"
        :icon="point.icon"
        :title="point.title"
        @click="detail(point)"
      ></bm-marker>
    </baidu-map>
    <div class="handler" v-show="!isView"></div>
    <v-dialog v-model="detailShow" persistent max-width="480">
      <v-card>
        <v-card-title>
          <div>{{ deviceName }} - 详情</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="detailShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          style="background-color: transparent; height: 360px; overflow-y: auto"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.alarmType`]="{ item }">
            <span v-if="item.alarmType == 1" class="error--text">
              超上限告警
            </span>
            <span v-if="item.alarmType == 2" class="error--text">
              超下限告警
            </span>
            <span v-if="item.alarmType == 3" class="error--text">
              临上限告警
            </span>
            <span v-if="item.alarmType == 4" class="error--text">
              临下限告警
            </span>
            <span v-if="item.alarmType == 5" class="error--text">
              数据异常告警
            </span>
            <span v-if="item.alarmType == 6" class="error--text">
              状态异常告警
            </span>
            <span v-if="item.alarmType == 7" class="success--text">正常</span>
            <span v-if="item.alarmType == 8" class="success--text">正常</span>
            <span v-if="item.alarmType == 9" class="error--text">脱机告警</span>
            <span v-if="item.alarmType == 10" class="error--text"
              >文本告警</span
            >
            <span v-if="item.alarmType == 11" class="error--text">
              极上限告警
            </span>
            <span v-if="item.alarmType == 12" class="error--text">
              极下限告警
            </span>
          </template>
        </v-data-table>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mapStyle from "../utils/map-style.json";
import proto from "../utils/proto";
import client from "../utils/client";
import base64 from "../utils/base64";
export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      mapStyle: {
        styleJson: mapStyle,
      },
      state: {},
      alarm: {},
      detailShow: false,
      deviceName: "",
      headers: [
        { text: "编号", value: "no" },
        { text: "名称", value: "name" },
        { text: "告警状态", value: "alarmType" },
        { text: "当前值", value: "value" },
      ],
      items: [],
      ready: false,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      points: [],
      map: null,
    };
  },
  watch: {
    "control.config": {
      handler() {
        this.change();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.isView) this.initData();
    if (this.isView) client.$on("brocast", this.brocast);
  },
  beforeDestroy() {
    if (this.isView) client.$off("brocast", this.brocast);
  },
  destroyed() {
    this.clean();
  },
  methods: {
    clean() {
      if (this.map) this.map.clearOverlays();
      if (window.$BAIDU$) {
        if (window.$BAIDU$._instances) {
          let keys = Object.keys(window.$BAIDU$._instances);
          for (let i in keys) {
            let key = keys[i];
            let instance = window.$BAIDU$._instances[key];
            if (instance == this.map) {
              delete window.$BAIDU$._instances[key];
              if (window.MPC_Mgr) delete window.MPC_Mgr[key];
              if (window.GCJMAP && window.GCJMAP.ba == key)
                delete window.GCJMAP;
            } else if (instance.map == null) {
              delete window.$BAIDU$._instances[key];
            }
          }
        }
      }
      this.map = null;
    },
    onReady(e) {
      this.map = e.map;
      this.ready = true;
      this.change();
    },
    change(ignore) {
      if (!this.ready) return;
      if (!ignore) {
        let center = this.control.config.mapCenter.split(",");
        let centerLng = parseFloat(center[0]) || 116.40354;
        let centerLat = parseFloat(center[1]) || 39.923425;
        let zoom = parseInt(this.control.config.mapZoom) || 3;
        this.center.lng = centerLng;
        this.center.lat = centerLat;
        this.zoom = zoom;
      }
      let results = [];
      let points = this.control.config.mapPoint || [];
      for (let i in points) {
        let point = points[i];
        let uuid = point.uuid;
        let lonlat = point.lonlat.split(",");
        let lng = parseFloat(lonlat[0]) || 116.40354;
        let lat = parseFloat(lonlat[1]) || 39.923425;
        let deviceId = point.device.id;
        let deviceName = point.device.name;
        let state = this.state[deviceId] || -1;
        let alarm = this.alarm[deviceId] || 0;
        let model = {
          key: uuid,
          lnglat: { lng, lat },
          id: deviceId,
          name: deviceName,
          state,
          alarm,
        };
        model.icon = this.getIcon(model);
        model.title = this.getTitle(model);
        results.push(model);
      }
      this.points = results;
    },
    async initData() {
      try {
        let points = this.control.config.mapPoint || [];
        let so = [];
        for (let i in points) {
          let point = points[i];
          if (point.device.id) {
            so.push({ uuid: point.device.id });
          }
        }
        if (so.length) {
          let res = await client.send(proto.MESSAGE_TYPE.statusMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "-1",
            },
            so,
          });
          if (res.so) {
            for (let i in res.so) {
              let status = res.so[i];
              this.state[status.uuid] = status.state;
              this.alarm[status.uuid] = status.stateAlarm;
            }
          }
          this.change();
        }
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    brocast(type, msg) {
      let needUpdate = false;
      switch (type) {
        case proto.MESSAGE_TYPE.realTimeEventMessage:
          if (msg.rtevent) {
            for (let i in msg.rtevent) {
              let event = msg.rtevent[i];
              for (let i in this.items) {
                let item = this.items[i];
                if (item.uuid == event.uuid) {
                  item.alarmType = event.stateAlarm;
                  break;
                }
              }
              if (this.alarm[event.uuid] != undefined) {
                this.alarm[event.uuid] = event.stateAlarm;
                needUpdate = true;
              }
            }
          }
          break;
        case proto.MESSAGE_TYPE.statusMessage:
          if (msg.so) {
            for (let i in msg.so) {
              let status = msg.so[i];
              for (let i in this.items) {
                let item = this.items[i];
                if (item.uuid == status.uuid) {
                  item.stateType = status.state;
                  item.alarmType = status.stateAlarm;
                  break;
                }
              }
              if (this.state[status.uuid] != undefined) {
                this.state[status.uuid] = status.state;
                needUpdate = true;
              }
              if (this.alarm[status.uuid] != undefined) {
                this.alarm[status.uuid] = status.stateAlarm;
                needUpdate = true;
              }
            }
          }
          break;
        case proto.MESSAGE_TYPE.realTimeDataMessage:
          if (msg.rtdata) {
            for (let i in msg.rtdata) {
              let data = msg.rtdata[i];
              for (let i in this.items) {
                let item = this.items[i];
                if (item.uuid == data.uuid) {
                  let value = "/";
                  if (data.sv && data.sv.valueData)
                    value = base64.decode(data.sv.valueData);
                  if (/[0-9]+[.]?[0-9]*/.test(value)) value = parseFloat(value);
                  item.value = value;
                  break;
                }
              }
            }
          }
          break;
      }
      if (needUpdate) this.change(true);
    },
    getIcon(point) {
      let img;
      if (point.state == -1 && point.alarm == 0) {
        img = "./res/map-marker-blue.png";
      } else if (point.state == 1) {
        if (point.alarm == 7 || point.alarm == 8) {
          img = "./res/map-marker-green.png";
        } else {
          img = "./res/map-marker-red.png";
        }
      } else {
        if (point.alarm != 7 && point.alarm != 8) {
          img = "./res/map-marker-red.png";
        } else {
          img = "./res/map-marker-grey.png";
        }
      }
      return {
        url: img,
        size: { width: 24, height: 24 },
        opts: { anchor: { width: 12, height: 24 } },
      };
    },
    getTitle(point) {
      let title = [point.name];
      if (point.id) {
        if (point.state == 1) {
          title.push(`联机状态：在线`);
        } else {
          title.push(`联机状态：离线`);
        }
        if (point.alarm == 9) {
          title.push(`告警状态：脱机告警`);
        } else if (point.alarm == 6) {
          title.push(`告警状态：测点告警`);
        } else {
          title.push(`告警状态：正常`);
        }
      }
      return title.join("\n");
    },
    async detail(point) {
      if (point.id) {
        await this.getSpots(point.id);
        await this.getStatus();
        await this.getData();
        this.deviceName = point.name;
        this.detailShow = true;
      }
    },
    async getSpots(deviceId) {
      let results = [];
      client.$emit(
        "startBusy",
        "ZhtBMap.getSpots",
        "正在获取测点,请稍候..."
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.spotMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
          },
          act: 1,
          devices: [deviceId],
        });
        if (res.spots && res.spots.length) {
          res.spots.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          for (let i = 0; i < res.spots.length; i++) {
            let spot = res.spots[i];
            spot.stateType = -1;
            spot.alarmType = 0;
            spot.value = "/";
            results.push(spot);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ZhtBMap.getSpots");
      this.items = results;
    },
    async getStatus() {
      client.$emit(
        "startBusy",
        "ZhtBMap.getStatus",
        "正在获取状态,请稍候..."
      );
      await proto.sleep(100);
      try {
        let so = [];
        for (let i in this.items) {
          let item = this.items[i];
          so.push({ uuid: item.uuid });
        }
        if (so.length) {
          let res = await client.send(proto.MESSAGE_TYPE.statusMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "-1",
            },
            so,
          });
          this.brocast(proto.MESSAGE_TYPE.statusMessage, res);
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ZhtBMap.getStatus");
    },
    async getData() {
      client.$emit(
        "startBusy",
        "ZhtBMap.getData",
        "正在获取数据,请稍候..."
      );
      await proto.sleep(100);
      try {
        let rtdata = [];
        for (let i in this.items) {
          let item = this.items[i];
          rtdata.push({ uuid: item.uuid });
        }
        if (rtdata.length) {
          let res = await client.send(
            proto.MESSAGE_TYPE.realTimeDataMessage,
            {
              mcd: {
                operate: proto.OperateMode.retrieveOpt,
              },
              filterType: 1,
              rtdata,
            }
          );
          this.brocast(proto.MESSAGE_TYPE.realTimeDataMessage, res);
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ZhtBMap.getData");
    },
  },
};
</script>
